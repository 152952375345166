import amplitude from "../../common/components/amplitude";
import {executeSafely} from "../../common/components/executeSafety";
import {logImgLoadError} from "../../common/components/ImglogError";
import '../../common/components/hamburger';

init();

function executePageSpecificFunction(page) {
    if (!page) {
        return;
    }

    const pageAttribute = page.getAttribute('data-page');

    if (!pageAttribute) {
        return;
    }

    const pageEvents = {
        'terms': () => amplitude.logEvent('Terms of Use View'),
        'policy': () => amplitude.logEvent('Privacy Policy View'),
    };

    if (pageEvents.hasOwnProperty(pageAttribute)) {
        pageEvents[pageAttribute]();
    }
}
function backBtnInit(backBtn) {
    if (!backBtn) {
        return;
    }

    backBtn.addEventListener('click', () => {
        window.history.go(-1);
    });
}
function init() {
    executeSafely(backBtnInit, document.getElementById('backBtn'));
    executeSafely(logImgLoadError);
    executeSafely(executePageSpecificFunction, document.querySelector('.service'));
}


